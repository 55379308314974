<template>
  <money
    v-bind="$attrs"
    v-model="inputValue"
    ref="input"
    :precision="0"
    class="input"
    @blur.native="blur"
    @focus.native="focus"
  />
</template>

<script>
import Input from './Input';
import { Money } from "v-money";

export default {
  name: 'InputMoney',
  extends: Input,
  components: {
    Money
  },
  data() {
    return {
      inputValue: ''
    };
  },
  methods: {
    blur(event) {
      this.$emit('blur', event);
    },
    focus(event) {
      this.$emit('focus', event);
    },
  },
  mounted() {
    this.inputValue = this.value;
  },
  watch: {
    inputValue() {
      this.$emit('change', this.inputValue);
    },
    value(value) {
      if (value !== this.inputValue) {
        this.inputValue = value;
      }
    },
  }
};
</script>
